html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  background-image: url('./Background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Fira Code", monospace;
}

#loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 20px;
}

#console {
  display: none;
  position: fixed;
  bottom: -100%;
  transition: bottom 2s ease-out;
  left: 50%;
  transform: translate(-50%, 50%);
  color: #fff;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  border: 10px solid rgb(136, 113, 38);
  background: rgb(157, 130, 42);
  border-radius: 10px;
}

#console-output {
  background-color: rgb(157, 130, 42);
  font-size: 13px;
  padding-left: 10px;
  padding-bottom: 10px;
  width: 80vw;
  height: 80vh;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  overflow-y: auto;
}

#console-input {
  width: 78vw;
  padding: 5px;
  background-color: rgb(136, 113, 38);
  color: #fff;
  border: none;
  outline: none;
}

#version {
  position: absolute;
  top: 3px;
  right: 3px;
  padding: 5px 10px;
  background-color: #333;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
}

@media only screen and (max-width: 680px) {
  #console-output {
    width: 100vw;
  }
}

@media only screen and (max-width: 540px) {
  #console-output {
    font-size: 10px;
  }
}

@media only screen and (max-width: 400px) {
  #console-output {
    font-size: 8px;
  }
}
