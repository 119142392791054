html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  background-image: url("Background.8c628def.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: Fira Code, monospace;
}

#loading {
  color: #fff;
  font-size: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#console {
  color: #fff;
  background: #9d822a;
  border: 10px solid #887126;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  transition: bottom 2s ease-out;
  display: none;
  position: fixed;
  bottom: -100%;
  left: 50%;
  transform: translate(-50%, 50%);
}

#console-output {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  background-color: #9d822a;
  width: 80vw;
  height: 80vh;
  padding-bottom: 10px;
  padding-left: 10px;
  font-size: 13px;
  overflow-y: auto;
}

#console-input {
  color: #fff;
  background-color: #887126;
  border: none;
  outline: none;
  width: 78vw;
  padding: 5px;
}

#version {
  color: #fff;
  background-color: #333;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  position: absolute;
  top: 3px;
  right: 3px;
}

@media only screen and (width <= 680px) {
  #console-output {
    width: 100vw;
  }
}

@media only screen and (width <= 540px) {
  #console-output {
    font-size: 10px;
  }
}

@media only screen and (width <= 400px) {
  #console-output {
    font-size: 8px;
  }
}
/*# sourceMappingURL=index.6f4c0cea.css.map */
